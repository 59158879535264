import { Box, Container, Link, Typography } from '@mui/material';

const NotFound = () => {
  return (
    <Box bgcolor="neutral02" height="100vh">
      <Container sx={{ my: 10 }}>
        <Typography variant="body1">KINE VAN MOER</Typography>
        <Typography variant="h1" color="global01">
          404: Pagina niet gevonden
        </Typography>
        <Link href="/">Ga terug</Link>
      </Container>
    </Box>
  );
};

export default NotFound;
